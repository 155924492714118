<template>
  <div>
    <div
        class="upload-file-container form-group"
        @click="onShowDialog"
        @drop="dropHandler"
        @dragover="dragOverHandler"
        @dragleave="dragLeave"
    >
      Прикрепить файл
      <input class="d-none" type="file" ref="fileInput" @change="onSelectFiles">
    </div>
    <ul class="list-unstyled">
      <li v-for="(file, index) in files" class="form-group">
        <a :href="baseUrl + '/member-file/download?url=' + file.url + '&token=' + user.accessToken" target="_blank" class="ml-3">
          {{file.name}}
        </a>
        <span class="icon-remove cursor-pointer" @click="onRemoveFile(index)">удалить файл</span>
      </li>
    </ul>
    <div :class="uploadProcess ? 'opacity-1' : 'opacity-0'">
      <img class="file-loader" src="../assets/loader.gif" />
    </div>
    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>
    <div class="alert-block">
      <div class="alert alert-success" v-show="alertSuccess.length > 0">{{alertSuccess}}</div>
    </div>
  </div>
</template>

<script>
import User from "@/components/User";

export default {
  name: "UploadFiles",
  props: {
    value: {
      type: Array
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL + '/api/exam',
      user: User,
      files: this.value,
      alert: '',
      alertSuccess: '',
      uploadProcess: false
    }
  },
  methods: {
    handleUploadFiles() {
      this.$emit('input', this.files)
    },

    /**
     * Показывает диалог с выбором файла.
     */
    onShowDialog() {
      const elem = this.$refs.fileInput
      elem.click()
    },

    dropHandler(event) {
      event.currentTarget.classList.remove('dragging');

      // можем загружать только 1 файл
      if (this.files.length === 1 || event.dataTransfer.items.length > 1) {
        this.uploadProcess = false
        this.alert = 'Разрешено прикреплять только 1 файл.'
        setTimeout(() => this.alert = '', 3000)
        event.preventDefault();
        return
      }

      this.uploadProcess = true
      event.dataTransfer.items.forEach((item) => {
        const file = item.getAsFile();
        const promise = this.uploadFile(file)
        promise
            .then((response) => {
              this.uploadProcess = false
              this.files.push(response.data)
              this.alertSuccess = 'Файл загружен'
              setTimeout(() => this.alertSuccess = '', 3000)
            })
            .catch((error) => {
              this.uploadProcess = false
              this.alert = 'Ошибка загрузки файла'
              setTimeout(() => this.alert = '', 3000)
            })
      })
      this.handleUploadFiles()

      event.preventDefault();
    },

    dragOverHandler(event) {
      event.currentTarget.classList.add('dragging');
      event.preventDefault();
    },

    dragLeave(event) {
      event.currentTarget.classList.remove('dragging');
      event.preventDefault();
    },

    /**
     * Выбирает и загружает файл.
     */
    onSelectFiles(e) {
      if (e.target.files.length === 0) {
        e.target.value = ''
        return
      }

      // можем загружать только 1 файл
      if (this.files.length === 1) {
        this.uploadProcess = false
        this.alert = 'Разрешено прикреплять только 1 файл.'
        e.target.value = ''
        setTimeout(() => this.alert = '', 3000)
        return
      }

      this.uploadProcess = true
      e.target.files.forEach((file) => {
        const promise = this.uploadFile(file)
        promise
            .then((response) => {
              this.uploadProcess = false
              this.files.push(response.data)
              this.alertSuccess = 'Файл загружен'
              setTimeout(() => this.alertSuccess = '', 3000)
            })
            .catch((error) => {
              this.uploadProcess = false
              this.alert = 'Ошибка загрузки файла'
              setTimeout(() => this.alert = '', 3000)
            })
        e.target.value = ''
      })

      this.handleUploadFiles()
    },

    /**
     * Удаляет файл.
     */
    onRemoveFile(index) {
      this.files.splice(index, 1)
    },

    /**
     * Загружает файл.
     * @param file
     * @return {Promise<any>}
     */
    async uploadFile(file) {
      let formData = new FormData()
      formData.append('file', file)
      return await this.$http.post('/file/upload?replaceName=' + User.regNumber, formData, {
        headers: {
          'Authorization': 'Bearer ' + User.accessToken,
          'Content-Type': 'multipart/form-data'
        }
      })
    }
  }
}
</script>
