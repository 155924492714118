<template>
  <div class="default-layout" v-show="authUser.isAuth()">
    <header class="header">
      <nav class="navbar-default">
        <div class="container-fluid">
          <section class="header-menu">
            <div class="navbar-block block-finish">
              <div class="d-flex cursor-pointer" @click="showFinisModal">
                <svg class="finish-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
                </svg>
                <span style="margin-left: 10px">Закончить экзамен</span>
              </div>
            </div>
            <div class="navbar-block text-center">
              <div>До окончания экзамена:</div>
              <div class="time-expired" v-html="timeText"></div>
            </div>
            <div class="navbar-block text-center pt-3">
              <UserInfo></UserInfo>
            </div>
          </section>
        </div>
      </nav>
    </header>
    <main class="container-fluid">
      <slot />

      <b-modal id="modal-finish" hide-footer scrollable size="lg" centered>
        <div v-if="Object.keys(result).length === 0">
          <div class="form-group">
            <div>
              <label>
                <input type="radio" :checked="attaches.length > 0" :disabled="attaches.length === 0">
                &nbsp;
                <strong>я проверил(а) и прикрепил(а) файл</strong> с решением к экзаменационному билету
              </label>
            </div>
            <div>
              <label>
                <input type="radio" :checked="attaches.length === 0" :disabled="attaches.length > 0">
                &nbsp;
                <strong>я не использовал(а) файл</strong> для решения экзаменационных заданий
              </label>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-danger" @click="finishExam">Подтверждаю. Закончить экзамен</button>
            <button class="btn btn-default" @click="cancel">Отменить</button>
          </div>
        </div>
        <div v-else>
          <h4 class="form-group">Экзамен закончен!</h4>
          <div class="row form-group">
            <div class="col-sm-7">
              <div class="finish-group">
                <div class="hint">ФИО претиндента</div>
                <div class="value">{{ username }}</div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="finish-group">
                <div class="hint">Дата экзамена</div>
                <div class="value">{{ result.examDate }}</div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-sm-12">
              <div class="finish-group">
                <div class="hint">Модуль</div>
                <div class="value">{{ result.modules[0].name }}</div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-sm-7">
              <div class="finish-group">
                <div class="hint">Вопросов отвечено</div>
                <div class="value">{{ result.stat.answered }} из {{ result.stat.total }}</div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="finish-group">
                <div class="hint">Файл с решением</div>
                <div class="value">{{ attaches.length > 0 ? 'Есть' : 'Нет'}}</div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="modal-expired-time" hide-footer scrollable size="lg" centered>
        <div class="form-group">
          <h5>Внимание!</h5>
          <p>Время для ответов на вопросы истекает.</p>
          <div><b>Проверьте заполнение</b> всех полей ответов.</div>
          <div><b>Сохраните ответы</b> во всех полях с помощью кнопки «Сохранить».</div>
          <div><b>Прикрепите файл</b> с решением (при наличии) на странице задания 1.</div>
        </div>
        <div>
          <button class="btn btn-danger" @click="$bvModal.hide('modal-expired-time')">OK</button>
        </div>
      </b-modal>

      <div class="alert-block">
        <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
      </div>
    </main>
  </div>
</template>
<script>
import UserInfo from "@/components/UserInfo";
import Socket from "@/components/socket";
import User from "@/components/User";

export default {
  components: {UserInfo, User},
  data() {
    return {
      authUser: User,
      username: User.getFullName(),
      timeText: '&nbsp;',
      result: {},
      alert: '',
      attaches: [],
      hasModalExpiredClose: false,
      timerNode: null
    }
  },
  created() {
    if (!User.isAuth()) {
      this.$router.push({name: 'Login'})
      return
    }

    Socket.start()
    Socket.subscribeTimer((response) => {
      this.timeText = response.text

      // цвет таймера
      this.checkExpiredTime(response.time)

      // показываем окно о скором окончании экзамена
      if (response.time > 0 && response.time <= 600 && !this.hasModalExpiredClose) {
        this.hasModalExpiredClose = true
        this.$nextTick(() => {
          this.$bvModal.show('modal-expired-time')
        })
      }

      // завершаем экзамен
      if (response.time < 0) {
        this.$http.post('/answer/case-finish', this.$store.getters.getAnswers, {
          headers: {
            Authorization: 'Bearer ' + this.authUser.accessToken
          }
        })
            .then((result) => {
              this.result = result.data
              this.authUser.logout()
              this.$bvModal.show('modal-finish')
              setTimeout(() => {
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-finish')
                })
                Socket.closeSocket()
                Socket.closeTimer()
                window.location.href = '/?status=finish'
              }, 10000)
            })
      }
    })
  },
  mounted() {
    this.timerNode = document.querySelector('.time-expired')
  },
  methods: {
    /**
     * Закрывает форму.
     */
    cancel() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-finish')
      })
    },

    /**
     * Показать окно для завершения экзамена.
     */
    showFinisModal() {
      this.validateAttachesFiles()
      this.$nextTick(() => {
        this.$bvModal.show('modal-finish')
      })
    },

    /**
     * Завершает экзамен.
     */
    finishExam() {
      this.$http.post('/demo/case-finish', this.$store.getters.getAnswers, {
        headers: {
          Authorization: 'Bearer ' + this.authUser.accessToken
        }
      })
          .then((response) => {
            Socket.send('finish_exam')
            this.result = response.data
            this.authUser.logout()
            setTimeout(() => {
              this.$nextTick(() => {
                this.$bvModal.hide('modal-finish')
              })
              Socket.closeSocket()
              Socket.closeTimer()
              window.location.href = '/?status=finish'
            }, 10000)
          })
          .catch((error) => {
            this.alert = 'Возникли проблемы с завершением экзамена.'
            setTimeout(() => this.alert = '', 3000)
          })
    },

    /**
     * Проверяет, прикреплены ли все файлы к ответам.
     */
    validateAttachesFiles() {
      this.attaches = []

      this.$store.getters.getAnswers.forEach((answer) => {
        if (answer.files.length > 0) {
          this.attaches.push(answer.segmentId)
        }
      })
    },

    /**
     * Проверяет окончание времени.
     * @param expired
     */
    checkExpiredTime(expired) {
      if (expired > 600 && expired <= 1800) {
        this.timerNode.classList.add('time-medium')
      } else if (expired > 300 && expired <= 600) {
        this.timerNode.classList.add('time-hard')
      } else if (expired > 0 && expired <= 300) {
        this.timerNode.classList.add('time-danger')
      } else {
        this.timerNode.classList.remove('time-medium', 'time-hard', 'time-danger')
      }
    }
  }
}
</script>