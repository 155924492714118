<template>
  <div class="rules">
    <h1 class="form-group">Правила проведения экзамена</h1>
    <div class="panel form-group">
      <div class="scrolled-content">
        <div class="content" v-if="levelId === 2">
          <p>Квалификационный экзамен на получение квалификационного аттестата аудитора (далее - экзамен) проводится Единой аттестационной комиссией в соответствии с Порядком проведения квалификационного экзамена лица, претендующего на получение квалификационного аттестата аудитора, утвержденным Решением Правления СРО ААС от 16 декабря 2022 года.  </p>
          <p>Экзамен сдается претендентом самостоятельно на русском языке без помощи переводчика и каких-либо иных лиц. </p>
          <p>Претендент обязан явиться на экзамен с паспортом гражданина Российской Федерации или иным документом, удостоверяющим личность, в ином случае претендент к сдаче экзамена не допускается и считается не явившимся на экзамен. </p>
          <p>При сдаче каждого модуля второго этапа экзамена все претенденты получают один набор заданий (вопросов). Количество вопросов, включаемых в набор заданий для каждого модуля второго этапа экзамена, определяется Комиссией. </p>
          <p>Для получения доступа к набору заданий претенденту необходимо ввести в программном комплексе Комиссии выданные представителем Комиссии логин и пароль. При входе претендента в программный комплекс начинается отсчет времени, предоставляемого для ответов на вопросы набора заданий. </p>
          <p>Претендент получает доступ к каждому следующему вопросу набора заданий для ответа вне зависимости от того, был ли им дан ответ на предыдущий вопрос. Последовательность ответов на вопросы набора заданий определяется претендентом самостоятельно. </p>
          <p>В течение времени, отведенного для ответов на все вопросы набора заданий, допускается возврат претендента к любым вопросам, а также корректировка ответов. </p>
          <p>Каждому претенденту предоставляется для ответов на все вопросы набора заданий не более 3 часов. Доступ претендента к набору заданий прекращается автоматически по истечении отведенного на ответы времени.  </p>
          <p>При проведении экзамена претендент может иметь при себе питьевую воду, лекарства и иные средства, обеспечивающие необходимые рабочие условия для прохождения экзамена (очки, слуховой аппарат и т.п.). Иные личные вещи претендента, в том числе мобильные средства связи, перед началом сдачи экзамена должны быть размещены в специально отведенном месте, определенном представителем Комиссии. При этом мобильные средства связи претендента должны быть выключены. </p>
          <p>При прохождении второго этапа экзамена претендент может пользоваться калькулятором, предназначенным только для выполнения операций над числами или алгебраическими формулами. </p>
          <p>При проведении второго этапа экзамена претенденту запрещается: </p>
          <p>а) пользоваться законодательными и иными нормативными правовыми актами Российской Федерации, справочными и иными материалами, а также средствами мобильной и иной связи, компьютерной и иной техники, кроме предоставленных Комиссией; </p>
          <p>б) вести переговоры с другими претендентами; </p>
          <p>в) вести какие-либо записи на бумажном или ином носителе информации (кроме бланков, предоставляемых претенденту Комиссией и подлежащих возврату Комиссии после сдачи экзамена); </p>
          <p>г) покидать помещение, в котором проводится экзамен, во время экзамена (за исключением случаев, когда решение принимается представителем Комиссии); </p>
          <p>д) выносить из помещения, в котором проводится экзамен, бумажные и иные носители информации с записью вопросов экзамена и ответов на них (решений). </p>
          <p>Претендент, нарушивший указанные требования, удаляется из пункта приема экзамена и считается не сдавшим экзамен. </p>
          <p>Претендент обязан выполнять указания представителя Комиссии, обеспечивающие соблюдение правил проведения экзамена. Представитель Комиссии имеет право удалить из помещения претендента, не выполнившего его указание, связанное с проведением экзамена. В таком случае претендент считается не сдавшим экзамен. </p>
          <p>С целью осуществления контроля за соблюдением порядка проведения экзамена Комиссия осуществляет видеосъемку экзамена. Комиссия имеет право принять решение об аннулировании результатов экзамена на основании видеозаписи, подтверждающей нарушение претендентом установленных правил проведения экзамена. </p>
          <p>Комиссия обеспечивает осуществление необходимых организационных и технических мер для защиты установленных Комиссией экзаменационных вопросов от неправомерного или случайного доступа к нему, уничтожения, изменения, блокирования, копирования, распространения вопросов, а также от иных неправомерных действий. Экзаменационные вопросы являются собственностью Комиссии. Категорически запрещается полностью или частично воспроизводить и (или) распространять экзаменационные вопросы Комиссии. </p>
          <p>В случае если при проведении экзамена произошел технический сбой в работе компьютерной техники (в частности, поломка компьютера, сбой программ для ЭВМ, отключение снабжения электрической энергией) или возникли иные обстоятельства, препятствующие претендентам продолжить экзамен (в частности, задымление, пожар), то Комиссия обеспечивает устранение технической неисправности (иных обстоятельств, препятствующих претендентам продолжить экзамен) и предоставляет претенденту возможность продолжить прохождение экзамена. При отсутствии возможности продолжения претендентом экзамена Комиссия устанавливает для него другую дату и время прохождения им экзамена (с учетом вопросов, на которые претендент дал ответы до момента технического сбоя компьютерной техники или возникновения иных обстоятельств, препятствующих претендентам продолжить экзамен). </p>
          <p>Оценка ответов на вопросы в наборе заданий производится в следующем порядке: </p>
          <p>а) Комиссия устанавливает максимальное количество баллов за правильный ответ на каждый отдельный вопрос в наборе заданий, которое указывается в полученном претендентом наборе заданий; </p>
          <p>б) количество баллов, набранное претендентом за ответ на каждый отдельный вопрос в наборе заданий, определяется Комиссией в зависимости от степени правильности такого ответа в пределах максимального количества баллов за правильный ответ. </p>
          <p>Оценка ответов на вопросы в наборе заданий производится по соответствующему модулю в целом путем суммирования оценок за ответы на каждый вопрос, входящий в набор заданий. </p>
          <p>Претендент, получивший за ответы на все вопросы набора заданий 55 и более процентов наибольшего количества баллов, считается сдавшим соответствующий модуль второго этапа экзамена.  </p>
          <p>Претендент имеет право подать апелляцию о нарушении положений, установленных в главе II Порядка.  Апелляция подается в Комиссию не позднее 22 рабочих дней с даты объявления результатов экзамена. Комиссия рассматривает апелляцию и принимает решение по ней в течение не более 22 рабочих дней с даты получения апелляции Комиссией. </p>
          <p>Апелляция на пересмотр результатов экзамена Порядком проведения экзамена не предусмотрена. Ответы претендентов, хранящиеся на цифровых и (или) бумажных носителях, являются собственностью Комиссии и претендентам не предоставляются.</p>
        </div>
        <div class="content" v-if="levelId === 3">
          <p>Квалификационный экзамен на получение квалификационного аттестата аудитора (далее - экзамен) проводится Единой аттестационной комиссией в соответствии с Порядком проведения квалификационного экзамена лица, претендующего на получение квалификационного аттестата аудитора, утвержденным Решением Правления СРО ААС от 16 декабря 2022 года.</p>
          <p>Экзамен сдается претендентом самостоятельно на русском языке без помощи переводчика и каких-либо иных лиц. </p>
          <p>Претендент обязан явиться на экзамен с паспортом гражданина Российской Федерации или иным документом, удостоверяющим личность, в ином случае претендент к сдаче экзамена не допускается и считается не явившимся на экзамен.</p>
          <p>При прохождении третьего этапа экзамена все претенденты получают одну комплексную задачу (далее - комплексная задача).</p>
          <p>Для получения доступа к комплексной задаче претенденту необходимо ввести в программном комплексе Комиссии выданные представителем Комиссии логин и пароль. При входе претендента в программный комплекс Комиссии начинается отсчет времени, предоставляемого для решения комплексной задачи. </p>
          <p>Каждому претенденту предоставляется для решения комплексной задачи не более 4 часов. Доступ претендента к комплексной задаче прекращается автоматически после решения комплексной задачи или по истечении отведенного времени.</p>
          <p>При прохождении экзамена претендент может иметь при себе питьевую воду, лекарства и иные средства, обеспечивающие необходимые рабочие условия для прохождения экзамена (очки, слуховой аппарат и т.п.). Иные личные вещи претендента, в том числе мобильные средства связи, перед началом сдачи экзамена должны быть размещены в специально отведенном месте, определенном представителем Комиссии. При этом мобильные средства связи претендента должны быть выключены.</p>
          <p>При прохождении третьего этапа экзамена претендент может пользоваться калькулятором, предназначенным только для выполнения операций над числами или алгебраическими формулами, а также законодательными и иными нормативными правовыми актами Российской Федерации, на которых основывается решение комплексной задачи. Доступ претендентов к указанным законодательным и иным нормативным правовым актам Российской Федерации при прохождении экзамена обеспечивает Комиссия.</p>
          <p>При проведении третьего этапа экзамена претенденту запрещается:</p>
          <p>а) пользоваться законодательными и иными нормативными правовыми актами Российской Федерации, справочными и иными материалами, а также средствами мобильной и иной связи, компьютерной и иной техники, кроме предоставленных Комиссией;</p>
          <p>б) вести переговоры с другими претендентами;</p>
          <p>в) вести какие-либо записи на бумажном или ином носителе информации (кроме бланков, предоставляемых претенденту Комиссией и подлежащих возврату Комиссии после сдачи экзамена);</p>
          <p>г) покидать помещение, в котором проводится экзамен, во время экзамена (за исключением случаев, когда решение принимается представителем Комиссии);</p>
          <p>д) выносить из помещения, в котором проводится экзамен, бумажные и иные носители информации с записью вопросов экзамена и ответов на них (решений).</p>
          <p>Претендент, нарушивший указанные требования, удаляется из пункта приема экзамена и считается не сдавшим экзамен.</p>
          <p>Претендент обязан выполнять указания представителя Комиссии, обеспечивающие соблюдение правил проведения экзамена. Представитель Комиссии имеет право удалить из помещения претендента, не выполнившего его указание, связанное с проведением экзамена. В таком случае претендент считается не сдавшим экзамен.</p>
          <p>С целью осуществления контроля за соблюдением порядка проведения экзамена Комиссия осуществляет видеосъемку экзамена. Комиссия имеет право принять решение об аннулировании результатов экзамена на основании видеозаписи, подтверждающей нарушение претендентом установленных правил проведения экзамена.</p>
          <p>Комиссия обеспечивает осуществление необходимых организационных и технических мер для защиты установленных Комиссией экзаменационных вопросов от неправомерного или случайного доступа к нему, уничтожения, изменения, блокирования, копирования, распространения вопросов, а также от иных неправомерных действий. Экзаменационные вопросы являются собственностью Комиссии. Категорически запрещается полностью или частично воспроизводить и (или) распространять экзаменационные вопросы Комиссии.</p>
          <p>В случае если при проведении экзамена произошел технический сбой в работе компьютерной техники (в частности, поломка компьютера, сбой программ для ЭВМ, отключение снабжения электрической энергией) или возникли иные обстоятельства, препятствующие претендентам продолжить экзамен (в частности, задымление, пожар), то Комиссия обеспечивает устранение технической неисправности (иных обстоятельств, препятствующих претендентам продолжить экзамен) и предоставляет претенденту возможность продолжить прохождение экзамена. При отсутствии возможности продолжения претендентом экзамена Комиссия устанавливает для него другую дату и время прохождения им экзамена (с учетом вопросов, на которые претендент дал ответы до момента технического сбоя компьютерной техники или возникновения иных обстоятельств, препятствующих претендентам продолжить экзамен). </p>
          <p>Оценка решения комплексной задачи производится в следующем порядке:</p>
          <p>а) Комиссия устанавливает максимальное количество баллов за правильное решение каждой составляющей комплексной задачи, которое указывается в тексте полученной претендентом комплексной задачи;</p>
          <p>б) количество баллов, набранное претендентом за решение каждой составляющей комплексной задачи, Комиссия определяет в зависимости от степени правильности такого решения в пределах максимального количества баллов за правильное решение.</p>
          <p>Оценка решения комплексной задачи в целом производится путем суммирования оценок за решение каждой составляющей этой задачи. Претендент, получивший 50 и более процентов наибольшего количества баллов, считается сдавшим третий этап экзамена и экзамен в целом.</p>
          <p>Претендент имеет право подать апелляцию о нарушении положений, установленных в главе II Порядка.  Апелляция подается в Комиссию не позднее 22 рабочих дней с даты объявления результатов экзамена. Комиссия рассматривает апелляцию и принимает решение по ней в течение не более 22 рабочих дней с даты получения апелляции Комиссией.</p>
          <p>Апелляция на пересмотр результатов экзамена Порядком проведения экзамена не предусмотрена. Ответы претендентов, хранящиеся на цифровых и (или) бумажных носителях, являются собственностью Комиссии и претендентам не предоставляются.</p>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <label>
        <b-checkbox v-model="check">
          я согласен(-на) с правилами проведения экзамена
        </b-checkbox>
      </label>
    </div>
    <div class="form-group">
      <button class="btn btn-danger text-uppercase" v-bind:disabled="!check" @click="startExam">Начать экзамен</button>
    </div>
  </div>
</template>

<script>
  import User from '@/components/User'

  export default {
    name: 'Rules',
    comments: {User},
    data() {
      return {
        levelId: 2,
        check: false
      }
    },
    created() {
      User.initData()
      this.levelId = User.levelId
    },
    methods: {
      startExam() {
        if (this.check) {
          this.$router.push({name: 'Exam'})
        }
      }
    }
  }
</script>