<template>
  <div>
    <section v-if="segment && !loading">
      <h5 class="form-group font-weight-bold module-name mt-4">
        {{ segment.case.module.name }}
      </h5>
      <b-tabs content-class="mt-3" class="tabs-segments">
        <b-tab lazy :title="'' + segment.id">
          <div class="form-group">
            <div class="panel form-group">
              <section class="segment">
                <div class="form-group text" v-html="segment.case.task.content"></div>
              </section>
            </div>
            <div class="panel form-group">
              <div class="questions">
                <b-tabs content-class="mt-3">
                  <b-tab v-bind:title="'Вопрос №' + (number + 1)" v-for="(question, number) in segment.case.questions">
                    <div class="segment question-item form-group">
                      <div class="text form-group" v-html="question.content"></div>
                      <section class="answer-form">
                        <div class="form-group" v-if="question.applicantAttaches.length > 0">
                          <div class="mb-1">Вспомогательные файлы:</div>
                          <ul class="list-unstyled">
                            <li v-for="file in question.applicantAttaches">
                              <a v-bind:href="file.url" target="_blank">{{file.name}}</a>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </section>

    <section class="text-center mt-4" v-if="loading">
      Загружаем данные ...
    </section>

    <section class="text-center text-danger mt-4" v-if="error.length > 0">
      {{ error }}
    </section>
  </div>
</template>

<script>

import Axios from "axios";

export default {
  name: "PreviewSegment",
  data() {
    return {
      segment: null,
      loading: true,
      error: ''
    }
  },
  created() {
    this.loading = true
    this.error = ''
    Axios.get(`/api/v1/segments/${this.$route.params.id}?token=${this.$route.params.token}`, {
      baseURL: process.env.VUE_APP_API_URL
    })
      .then((response) => {
        this.segment = response.data
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.error = 'Не удалось загрузить данные, попробуйте позднее.'
      })
  }
}
</script>