import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DefaultLayout from './layouts/default-layout'
import LoginLayout from './layouts/login-layout'
import RulesLayout from './layouts/rules-layout'
import PreviewLayout from './layouts/preview-layout'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false

Vue.component('default-layout', DefaultLayout);
Vue.component('login-layout', LoginLayout);
Vue.component('rules-layout', RulesLayout);
Vue.component('preview-layout', PreviewLayout);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use( CKEditor );

Vue.prototype.$http = Axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api/exam'
});

Sentry.init({
  Vue,
  dsn: "https://8aceb85c0e0f40b08393e3be153993c4@o762934.ingest.sentry.io/5793680",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
