<template>
  <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @input="handleInput"></ckeditor>
</template>

<script>
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "CKEditor",
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      content: this.value,
      editor: ClassicEditor,
      editorData: this.value,
      editorConfig: {
        language: 'ru'
      }
    };
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.editorData)
    }
  }
}
</script>
