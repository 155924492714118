export default {
    id: null,
    name: null,
    surname: null,
    middleName: null,
    role: null,
    accessToken: null,
    regNumber: null,
    levelId: null,

    /**
     * Возвращает полное имя пользователя.
     * @return {string}
     */
    getFullName() {
        this.initData()
        return this.surname + ' ' + this.name + ' ' + this.middleName
    },

    /**
     * Возвращает true, если пользователь авторизован.
     * @return {boolean}
     */
    isAuth() {
      this.initData()
      return this.accessToken != null
    },

    /**
     * Авторизовывает пользователя.
     * @param data
     */
    login(data) {
        this.id = data.id
        this.name = data.name
        this.surname = data.surname
        this.middleName = data.middleName
        this.role = data.role
        this.accessToken = data.accessToken
        this.regNumber = data.applicant.regNumber
        this.levelId = data.levelId
        this.setData()
    },

    /**
     * Выходит из сессии пользователя.
     */
    logout() {
        this.id = null
        this.name = null
        this.surname = null
        this.middleName = null
        this.role = null
        this.accessToken = null
        this.regNumber = null
        this.levelId = null;
        localStorage.removeItem('user')
    },

    /**
     * Инициализирует данные из LocalStorage
     */
    initData() {
        let data = localStorage.getItem('user')
        if (data !== null && data.length > 0) {
            data = JSON.parse(data)
            this.id = data.id
            this.name = data.name
            this.surname = data.surname
            this.middleName = data.middleName
            this.role = data.role
            this.accessToken = data.accessToken
            this.regNumber = data.regNumber
            this.levelId = data.levelId;
        }
    },

    /**
     * Записывает данные в LocalStorage
     */
    setData() {
        localStorage.setItem('user', JSON.stringify({
            id: this.id,
            name: this.name,
            surname: this.surname,
            middleName: this.middleName,
            rol: this.role,
            accessToken: this.accessToken,
            regNumber: this.regNumber,
            levelId: this.levelId
        }))
    }
}