<template>
  <div class="home">
    <div class="panel form-group mt-3" v-show="isFinishedExam">
      <h5>Экзамен закончен!</h5>
    </div>
    <div class="row form-group" v-show="segmentsCount > 0">
      <div class="col-md-6">
        <span class="d-none">Осталось вопросов {{notAnsweredCount}} из {{segmentsCount}}</span>
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-default text-uppercase" v-b-modal.modal-help>
          <b-icon icon="info-circle-fill" class="icon" style="fill: #555"></b-icon>
          &nbsp; &nbsp; &nbsp;
          помощь
        </button>
      </div>
    </div>

    <h5 class="form-group font-weight-bold module-name" v-if="ticket.segments.length > 0">
      {{ticket.segments[0].case.module.name}}
    </h5>

    <b-tabs content-class="mt-3" class="tabs-segments" v-model="currentIndex">
      <b-tab lazy v-bind:title="'' + (index + 1)" :title-item-class="segment.isAnswered ? 'answered' : ''" v-for="(segment, index) in ticket.segments">
        <div class="form-group">
          <div class="panel form-group">
            <section class="segment">
              <div class="form-group text" v-html="segment.case.task.content"></div>
            </section>
          </div>
          <div class="panel form-group">
            <div class="questions">
              <b-tabs content-class="mt-3">
                <b-tab v-bind:title="'Вопрос №' + (number + 1)" v-for="(question, number) in segment.case.questions">
                  <div class="segment question-item form-group">
                    <div class="text form-group" v-html="question.content"></div>
                    <section class="answer-form">
                      <div class="form-group" v-if="question.applicantAttaches.length > 0">
                        <div class="mb-1">Вспомогательные файлы:</div>
                        <ul class="list-unstyled">
                          <li v-for="file in question.applicantAttaches">
                            <a v-bind:href="createFileUrl(file)" target="_blank">{{file.name}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="form-group mt-4">
                        <label><b>Поле для ответа</b></label>
                        <CKEditor v-model="getAnswer(question.id).answer"></CKEditor>
                      </div>
                      <div class="form-group" v-if="index === 0 && number === 0">
                        <UploadFiles v-model="getAnswer(question.id).files"></UploadFiles>
                      </div>
                    </section>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <button class="btn btn-bordered text-uppercase d-none" @click="onSkip">ответить позже</button>
              <button class="btn btn-danger text-uppercase" @click="onSaveAnswer" v-bind:disabled="!checkEnableAnswer(segment.id)">сохранить</button>
            </div>
            <div class="alert alert-success success-send-answer" v-if="sendAnswer">
              Ответ сохранён
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>

    <b-modal id="modal-help" scrollable centered size="lg" ok-only ok-title="Закрыть">
      <div v-if="authUser.levelId === 2">
        <p>Строка с номерами ЗАДАНИЙ расположена в верхней части экрана. Текущее задание выделено синим цветом.</p>
        <p>Чтобы перейти к другому заданию, нажмите соответствующий номер задания левой кнопкой мыши.</p>
        <p>В каждое ЗАДАНИЕ входит несколько ВОПРОСОВ. Ниже основного текста задания расположена строка с номерами вопросов. Текущий вопрос выделен синим цветом. Чтобы перейти к другому вопросу, нажмите соответствующий номер вопроса левой кнопкой мыши.</p>
        <p>В вопросе могут содержаться дополнительные условия или описание ситуации. Ниже – поле для ответа <u>на данный вопрос</u>. </p>
        <p>Претендент получает <strong>доступ к любому заданию и входящему в него вопросу</strong> вне зависимости от того, был ли им дан ответ на предыдущие задания и вопросы. Последовательность ответов на вопросы набора заданий определяется претендентом самостоятельно.</p>
        <p>В течение времени, отведенного для ответов, <strong><u>допускается возврат</u></strong> претендента к <u>любым</u> вопросам и <u> <strong>изменение/дополнение</strong></u>ответов. </p>
        <h5>Ввод ответов</h5>
        <p>Поле ответа предназначено для ввода ответа <strong>на текущий вопрос</strong> (номер текущего вопроса выделен синим цветом). В поле ответа можно вводить текст и создавать таблицы с использованием встроенного редактора. </p>
        <p>Можно скопировать часть текста и/или таблицу из задания и вставить в поле ответа, если это необходимо. </p>
        <p>Для сохранения ответа нажмите «Сохранить». </p>
        <p>Для правильного ответа на ЗАДАНИЕ в целом следует заполнить поля ответов<u> всех вопросов, входящих в задание</u>. </p>
        <p>После заполнения и сохранения ответов на все вопросы Задания номер Задания будет выделен зеленым цветом.</p>
        <h5>Вспомогательный файл</h5>
        <p>Часть информации из условия в виде больших таблиц может предоставляться также в файле в формате Еxcel.</p>
        <p>Для использования приложенного к экзаменационному билету файла скачайте его <u>со страницы вопроса № 1 задания № 1</u>.</p>
        <p>После скачивания файл попадает в память компьютера в раздел Загрузки. <u>Название</u> файла формируется автоматически и включает идентификационные данные претендента и НЕ ПОДЛЕЖИТ ИЗМЕНЕНИЮ претендентом.</p>
      </div>
      <div v-if="authUser.levelId === 3">
        <p>Строка с номерами ЗАДАНИЙ расположена в верхней части экрана. Текущее задание выделено синим цветом.</p>
        <p>Чтобы перейти к другому заданию, нажмите соответствующий номер задания левой кнопкой мыши.</p>
        <p>В каждое ЗАДАНИЕ входит несколько ВОПРОСОВ. Ниже основного текста задания расположена строка с номерами вопросов. Текущий вопрос выделен синим цветом. Чтобы перейти к другому вопросу, нажмите соответствующий номер вопроса левой кнопкой мыши.</p>
        <p>В вопросе могут содержаться дополнительные условия или описание ситуации. Ниже – поле для ответа <u>на данный вопрос</u>. </p>
        <p>Претендент получает <strong>доступ к любому заданию и входящему в него вопросу</strong> вне зависимости от того, был ли им дан ответ на предыдущие задания и вопросы. Последовательность ответов на вопросы набора заданий определяется претендентом самостоятельно.</p>
        <p>В течение времени, отведенного для ответов, <strong><u>допускается возврат</u></strong> претендента к <u>любым</u> вопросам и <u> <strong>изменение/дополнение</strong></u>ответов. </p>
        <h5>Ввод ответов</h5>
        <p>Поле ответа предназначено для ввода ответа <strong>на текущий вопрос</strong> (номер текущего вопроса выделен синим цветом). В поле ответа можно вводить текст и создавать таблицы с использованием встроенного редактора. </p>
        <p>Можно скопировать часть текста и/или таблицу из задания и вставить в поле ответа, если это необходимо. </p>
        <p>Для сохранения ответа нажмите «Сохранить». </p>
        <p>Для правильного ответа на ЗАДАНИЕ в целом следует заполнить поля ответов<u> всех вопросов, входящих в задание</u>. </p>
        <p>После заполнения и сохранения ответов на все вопросы Задания номер Задания будет выделен зеленым цветом.</p>
        <h5>Вспомогательный файл</h5>
        <p>Часть информации из условия в виде больших таблиц может предоставляться также в файле в формате Еxcel.</p>
        <p>Для использования приложенного к экзаменационному билету файла скачайте его <u>со страницы вопроса № 1 задания № 1</u>.</p>
        <p>После скачивания файл попадает в память компьютера в раздел Загрузки. <u>Название</u> файла формируется автоматически и включает идентификационные данные претендента и НЕ ПОДЛЕЖИТ ИЗМЕНЕНИЮ претендентом.</p>
        <p>Файл содержит Листы (Sheets), соответствующие заданиям и вопросам билета. В файл можно копировать условия и таблицы со страницы с заданиями.</p>
        <p>Таблицы и текст из файла можно копировать в поля ответов на вопросы.</p>
        <p>Для ответа на все вопросы всех заданий используйте ОДИН ФАЙЛ, размещая ответы на соответствующих листах. Перед завершением экзамена сохраните изменения в файле, <strong>прикрепите заполненный файл</strong> на ту же страницу, с которой Вы его скачали, нажав «Прикрепить файл». </p>
        <h5>Завершение экзамена</h5>
        <p>Проверьте заполнение всех полей ответов. Сохраните ответы во всех полях с помощью кнопки «Сохранить». </p>
        <p>Прикрепите файл Excel с решениями на странице <u>вопроса № 1 задания № 1.</u></p>
        <p>Чтобы завершить экзамен, нажмите «Закончить экзамен» в левом верхнем углу экрана.</p>
      </div>
    </b-modal>

  </div>
</template>

<script>

import CKEditor from "@/components/CKEditor";
import UploadFiles from "@/components/UploadFiles";
import Socket from '@/components/socket'
import User from "@/components/User";

export default {
  name: 'Exam',
  components: {UploadFiles, CKEditor},
  data() {
    return {
      authUser: User,
      currentIndex: 0,
      ticket: {},
      segmentsCount: 0,
      notAnsweredCount: 0,
      answers: [],
      alert: '',
      isFinishedExam: false,
      sendAnswer: false,
    }
  },
  created() {
    Socket.subscribe('private', 'ticket', (response) => {
      // проверяем, закончен ли экзамен
      if (response.status === 400) {
        this.authUser.logout()
        location.reload()
        return
      }

      // сохраняем данные билета
      this.saveTicket(response.data)
    })
    Socket.subscribeTimer((response) => {
      if (response.time > 0 && response.time <= 60) {
        document.querySelector('.time-expired').classList.add('time-danger')
      } else {
        document.querySelector('.time-expired').classList.remove('time-danger')
      }
    })
    this.startAutoSaveService()
  },
  methods: {
    /**
     * Возвращает true, если претендент заполнил нужные данные и можно включать кнопку ответа.
     */
    checkEnableAnswer(segmentId) {
      const answers = this.getListAnswers(segmentId)

      for (let i = 0; i < answers.length; i++) {
        if (answers[i].answer.trim().length > 0 || answers[i].files.length > 0) {
          return true
        }
      }

      return false
    },

    /**
     * Пропускает сегмент, но при этом сохраняет текущий резальтат.
     */
    onSkip() {
      this.$http.post('/answer/case?checkAnswered=0', this.answers, {
        headers: {
          Authorization: 'Bearer ' + User.accessToken
        }
      })
          .then((response) => {
            this.moveToNext()
          })
          .catch((error) => {
            this.alert = 'Возникли ошибки при сохранении ответа'
            setTimeout(() => this.alert = '', 3000)
          })
    },

    /**
     * Сохраняет ответы.
     */
    onSaveAnswer() {
      this.$http.post('/answer/case', this.answers, {
        headers: {
          Authorization: 'Bearer ' + User.accessToken
        }
      })
          .then((response) => {
            // отмечаем сегменты, по которым были даны ответы
            this.notAnsweredCount = 0
            for (let i = 0; i < this.ticket.segments.length; i++) {
              if (response.data.indexOf(this.ticket.segments[i].id) !== -1) {
                this.ticket.segments[i].isAnswered = 1
              } else {
                this.notAnsweredCount++
              }
            }
            // переходим к следующему сегменту
            //this.moveToNext()

            // показываем уведомление
            this.sendAnswer = true
            setTimeout(() => this.sendAnswer = false, 3000)
          })
          .catch((error) => {
            this.alert = 'Возникли ошибки при сохранении ответа'
            setTimeout(() => this.alert = '', 3000)
          })
    },

    /**
     * Возвращает ответ по ID вопроса
     * @param questionId
     * @return {*}
     */
    getAnswer(questionId) {
      const index = this.answers.map((item) => item.questionId).indexOf(questionId)
      return this.answers[index]
    },

    /**
     * Возвращет список ответов по сегменту.
     */
    getListAnswers(segmentId) {
      let result = []

      this.answers.forEach((item) => {
        if (item.segmentId === segmentId) {
          result.push(item)
        }
      })

      return result
    },

    /**
     * Переключает на следующий сегмент.
     */
    moveToNext() {
      for (let i = this.currentIndex; i < this.ticket.segments.length; i++) {
        if (!this.ticket.segments[i].isAnswered && this.currentIndex !== i) {
          this.currentIndex = i;
          return
        }
      }

      for (let i = 0; i < this.currentIndex; i++) {
        if (!this.ticket.segments[i].isAnswered && this.currentIndex !== i) {
          this.currentIndex = i;
          return
        }
      }
    },

    /**
     * Запускает сервис по автосохранению.
     */
    startAutoSaveService() {
      Socket.startAutoSaveService(() => {
        this.saveAllAnswers()
      })
    },

    /**
     * Останавливает сервис по автосохранению.
     */
    closeAutoSaveService() {
      Socket.closeAutoSaveService()
    },

    /**
     * Сохраняет все ответы.
     */
    saveAllAnswers() {
      this.$http.post('/answer/case', this.answers, {
        headers: {
          Authorization: 'Bearer ' + User.accessToken
        }
      })
    },

    /**
     * Подготавливает и сохраняет данные о билете в стор.
     * @param data
     */
    saveTicket(data) {
      data.segments.forEach((segment) => {
        segment.case.questions.forEach((question) => {
          if (question.applicantAnswer) {
            this.answers.push({
              segmentId: segment.id,
              questionId: question.id,
              answer: question.applicantAnswer.answer ? question.applicantAnswer.answer : '',
              files: question.applicantAnswer.files
            })
          } else {
            this.answers.push({
              segmentId: segment.id,
              questionId: question.id,
              answer: '',
              files: []
            })
          }
        })
        if (!segment.isAnswered) {
          this.notAnsweredCount++
        }
      })

      this.ticket = data
      this.segmentsCount = this.ticket.segments.length
      this.$store.commit('setTicket', this.ticket)
      this.$store.commit('setAnswers', this.answers)
    },

    /**
     * Создаёт URL для скачивания файла.
     * @param file
     * @return {string}
     */
    createFileUrl(file) {
      const attachName = this.ticket.segments[0].case.module.attachName
      const date = (new Date()).toLocaleDateString().replaceAll('/', '.')
      return file.url + '&replaceName=' + attachName + '_' + date + '_' + this.authUser.regNumber + '_' + this.ticket.number
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (!this.authUser.isAuth()) {
        return
      }

      if(document.hidden) {
        console.log('Tab InActive')
        Socket.closeSocket()
        Socket.closeTimer()
        this.closeAutoSaveService()
        this.saveAllAnswers()
      }
      else {
        console.log('Tab Active')
        Socket.startSocket()
        Socket.startTimer()
        this.startAutoSaveService()
      }
    });
  }
}

</script>
