import Vue from 'vue'
import VueRouter from 'vue-router'
import Exam from '../views/Exam.vue'
import Rules from '../views/Rules'
import Login from '../views/Login'
import PreviewSegment from "../views/PreviewSegment";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'login-layout'
    }
  },
  {
    path: '/exam',
    name: 'Exam',
    component: Exam,
  },
  {
    path: '/preview/:id/:token',
    name: 'PreviewSegment',
    component: PreviewSegment,
    meta: {
      layout: 'preview-layout'
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      layout: 'rules-layout'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
