import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ticket: {},
    answers: []
  },
  getters: {
    getTicket: state => {
      return state.ticket
    },
    getAnswers: state => {
      return state.answers
    }
  },
  mutations: {
    setTicket: (state, data) => {
      state.ticket = data
    },
    setAnswers: (state, data) => {
      state.answers = data
    }
  },
  modules: {
  }
})
